@font-face {
	font-family: 'Lato';
	src: url("./Lato/Lato-Regular.ttf") format("truetype");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'Lato';
	src: url("./Lato/Lato-Bold.ttf") format("truetype");
	font-style: normal;
	font-weight: 700;
}
